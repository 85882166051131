$(document).foundation();

$(document).ready(function(){
	$('.home-artists-carousel').slick({
  	autoplay: true,
  	autoplaySpeed: 5000,
  	centerMode: false,
  	fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
    {
      breakpoint: 640,
      settings: {
        arrows: true,
        //centerMode: true,
        //centerPadding: '25px',
        slidesToShow: 1
      }
    }
  ]
  });

  $('.artist-carousel').slick({
  	autoplay: true,
  	autoplaySpeed: 5000,
    infinite: true,
    dots: true,
    slidesToShow: 1,
    fade: true
  });

});